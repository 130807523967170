import { ContentfulVectorBasics } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { Tag as TagProps } from '../../models/Tag';
import { VectorBasics } from '../../models/VectorBasics';

export const useVectorBasicsPost = (
    data: ContentfulVectorBasics): VectorBasics => {
  return {
    id: data.id,
    title: data.titulo,
    subtitle: data.subtitulo,
    author: data.autor?.nombre,
    authorImg: data.autor?.foto
      ? data.autor?.foto
      : 'https://images.ctfassets.net/rmz81tjgnatt/5lUaPcdzsT5ipE63OWm2Rj/e98928355712ae8e05138e89e39569d2/Group__1_.png',
    authorObject: {
      id: data.autor?.id,
      nombre: data.autor?.nombre,
      avatar: data.autor?.foto,
    },
    slug: data.slug,
    tags: data.tags
      ? data.tags.map((tag: TagProps) => {
          return {
            nombre: tag.nombre,
            slug: tag.slug,
          };
        })
      : null,
    description: data.resumen,
    textohead: data.textoHead,
    imagehead: data.imagenHead,
    textogeneral: data.textoGeneral,
    date: data.createdAt ? parseDate(data.createdAt) : 'Fecha de publicación'
  };
};
