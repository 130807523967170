import React from 'react';
import { BLOCKS, MARKS, INLINES, Document } from '@contentful/rich-text-types';
import {
  Options,
  RenderMark,
  RenderNode,
} from '@contentful/rich-text-react-renderer';

const Bold = ({ children }: RenderMark): JSX.Element => <b>{children}</b>;
const Text = ({ children }: RenderNode): JSX.Element => <p>{children}</p>;

const options: Options = {
  renderMark: {
    // eslint-disable-next-line react/display-name
    [MARKS.BOLD]: (text: any): JSX.Element => <Bold>{text}</Bold>,
  },
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node: any, children: any): JSX.Element => (
      <Text>{children}</Text>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any): JSX.Element => {
      // If you are using contenful.js sdk, the referenced entry is resolved
      // automatically and is available at `node.data.target`.
      // console.log("node data target")
      // console.log(node.data.target.fields.slug["es-MX"])
      // console.log("children")
      // console.log(children)

      // console.log(node)
      switch (node.data.target.sys?.contentType?.sys.id) {
        case 'mercadosApertura':
          // console.log("mercados apertura")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/mercados-a-la-apertura/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'pulsoTecnico':
          // console.log("pulso tecnico")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                // href={
                //   'https://vectoranalisis.mx/tecnico/diario-tecnico/' +
                //   node.data.target.fields.slug['es-MX']
                // }
                href={
                  'https://vectoranalisis.mx/tecnico/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'flashTecnico':
          // console.log("flash tecnico")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                // href={
                //   'https://vectoranalisis.mx/tecnico/flash/' +
                //   node.data.target.fields.slug['es-MX']
                // }
                href={
                  'https://vectoranalisis.mx/tecnico/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'flashEconomico':
          // console.log("flash economico")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/economico/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'flashEmisora':
          // console.log("flash emisora")
          return (
            <React.Fragment>
              &nbsp;
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  'https://vectoranalisis.mx/renta-variable/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'flashInternacional':
          // console.log("flash internacional")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/internacional/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'flashRentaFija':
          // console.log("flash renta fija")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/renta-fija/flash/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'diarioEconomico':
          // console.log("diario economico")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/economico/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'estrategiaDeInversion':
          // console.log("estrategia de inversion")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/estrategia-de-inversion//' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'expectativasReporteTrimestral':
          // console.log("expectativasReporteTrimestral")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/renta-variable/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'expectativasFinancierasAnuales':
          // console.log("expectativasFinancierasAnuales")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/renta-variable/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'inicioDeCobertura':
          // console.log("inicioDeCobertura")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/renta-variable/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        case 'reporteTrimestral':
          // console.log("inicioDeCobertura")
          return (
            <React.Fragment>
              &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://vectoranalisis.mx/renta-variable/' +
                  node.data.target.fields.slug['es-MX']
                }
                style={{ fontWeight: 700, color: '#FF6600' }}
              >
                {children}
              </a>
              &nbsp;
            </React.Fragment>
          );
        // break
        default:
          // console.log('default');
          return (
            <React.Fragment>
              &nbsp;
              <span style={{ fontWeight: 700, color: '#FF6600' }}>
                {' '}
                {children}{' '}
              </span>
              &nbsp;
            </React.Fragment>
          );
        // break
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any): JSX.Element => {
      // console.log('node hyperlink');
      // console.log(node);
      return (
        <React.Fragment>
          &nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            // href={node.data.target.fields?.file['es-MX'].url}
            href={node.data.target.fields?.file['es-MX'].url}
            style={{ fontWeight: 700, color: '#FF6600' }}
          >
            {children}
          </a>
          &nbsp;
        </React.Fragment>
      );
    },
    [INLINES.HYPERLINK]: (node: any, children: any): JSX.Element => {
      //console.log('node hyperlink');
      //console.log(node);
      return (
        <React.Fragment>
          &nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={node.data.uri}
            style={{ fontWeight: 700, color: '#FF6600' }}
          >
            {children}
          </a>
          &nbsp;
        </React.Fragment>
      );
    },
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export default options;
