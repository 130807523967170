import React, { useEffect, useState } from 'react';

import { graphql, Link } from 'gatsby';
import { ThemeProvider, CssBaseline, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Timeline } from 'react-twitter-widgets'
import { renderRichText } from "gatsby-source-contentful/rich-text"


import { main } from '../theme';
import CustomFooter from '../components/CustomFooter';

import options from '../utils/getRichTextOptions';

import { ContentfulVectorBasics } from '../../types/graphql-types';
import { useHomePosts } from '../operations/queries/homePosts';
import { HomePosts as HomePostsProps } from '../models/HomePosts';
import { useVectorBasicsPost } from '../operations/queries/vectorBasicsPost';
import { VectorBasics as VectorBasicsPostsProps } from '../models/VectorBasics';

import '../theme/basics.css';
import useIsClient from '../utils/isClient';
import HeaderLayout from '../components/layout/HeaderLayout';
import ModalSuscribe from '../components/layout/ModalSuscribre';
import RecentPosts from '../components/layout/RecentPosts';
import { HeadApi } from '../components/HeadApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: '110px',
      marginBottom: '15px',
      fontSize: '45px',
      fontWeight: 900,
    },
    HeaderBasics:{
      width: "100%",
      "& .header--tags":{
        display: "flex",
        color:"yellow"
      }
    },
    HeaderAutor:{
      display: "flex",
      color:"yellow",
      alignItems: 'center',
      marginBottom: "1em"
    },
    HeaderAutorInfo:{
      display: "flex",
      alignItems: 'center',
      "& p" : {
        marginBottom:"0",
        marginRight:"1rem",
        color:"#959390",
        fontWeight: "500"
      }
    },
    HeaderTags:{
      marginBottom:"35px",
      "& span": {
        marginRight: '3px',
        borderRadius: '12px',
        padding: '5px 12px',
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '1',
        color: '#FFF',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        display: 'inline-block',
        backgroundColor: '#08355E',
      }
    },
    IntroBasics:{
      marginBottom:".5rem",
      paddingRight:"1rem",
      "& .wrap_text":{
        "& div p":{
          marginBottom:".5rem"
        }
      },
      "& .wrap_img":{
        padding: ".5rem 0",
        "& div":{
          width:"100%",
          height:"100%",
          overflow:"hidden",
          "& img":{
            width:"100%",
            height:"100%",
            objectFit:"cover"
          }
        }
      }
    },
    BodyBasics:{
      paddingRight:"1rem",
      "& p":{
        marginBottom: ".5rem"
      }
    },
    wrapInsta:{
      padding: "0.5rem",
      overflowY: "scroll",
      height: "420px",
      marginBottom: "1rem"
    },
    postInsta:{
      marginBottom:"1rem",
      "& .header_insta":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height:"54px",
        padding:"10px",
        background: "#fff",
        borderRadius: "8px 8px 0 0",
        boxShadow: [
          '0 1px 2px rgba(0,0,0,0.05)',
          '0 2px 4px rgba(0,0,0,0.05)',
          '0 4px 8px rgba(0,0,0,0.05)',
          '0 8px 16px rgba(0,0,0,0.05)',
          '0 16px 32px rgba(0,0,0,0.05)',
          '0 32px 64px rgba(0,0,0,0.05)',
        ],
      },
      "& .insta_video":{
        lineHeight:"0",
        "& video":{
          width:"100%",
          height:"100%"
        }
      }
    },
    avatarPic: {
      width: '80px',
      height: '80px',
      marginRight: 15,
      boxShadow: [
        '0 1px 2px rgba(0,0,0,0.05)',
        '0 2px 4px rgba(0,0,0,0.05)',
        '0 4px 8px rgba(0,0,0,0.05)',
        '0 8px 16px rgba(0,0,0,0.05)',
        '0 16px 32px rgba(0,0,0,0.05)',
        '0 32px 64px rgba(0,0,0,0.05)',
      ],
    },
    container: {
      // marginTop: 70,
      paddingTop:"50px",
      paddingBottom:"50px",
      paddingRight: '20px',
      paddingLeft: '20px',
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100%',
      '@media (min-width: 576px)': {
        maxWidth: '540px',
      },
      '@media (min-width: 768px)': {
        maxWidth: '720px',
      },
      '@media (min-width: 992px)': {
        maxWidth: '960px',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1140px',
      },
    },
  })
);

interface Props {
  data: {
    contentfulVectorBasics: ContentfulVectorBasics;
  };
  showFooter: boolean
}

export const Head = ({data}) => {
  const post: VectorBasicsPostsProps = useVectorBasicsPost(
    data.contentfulVectorBasics
  );

  
  

  console.log("[POST HEAD]", post.imagehead?.gatsbyImageData.images.sources[0].srcSet.split(",").slice(-1)[0].split(" ")[0]);

  const image = "https://vectoranalisis.mx/static/images/vector/analisis.png";

  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.imagehead?.gatsbyImageData.images?.fallback == undefined ? post.imagehead?.gatsbyImageData.images.sources[0].srcSet.split(",").slice(-1)[0].split(" ")[0] : post.imagehead?.gatsbyImageData.images?.fallback.src}
    />
  );
};

export default function VectorBasics({ 
    showFooter = true,
    data
  }:Props 
): JSX.Element {

  const dataRecent: HomePostsProps = useHomePosts();
  const post: VectorBasicsPostsProps = useVectorBasicsPost(
    data.contentfulVectorBasics
  );  
  console.log(post);
  const classes = useStyles();
  const [dataInsta, setDataInsta] = useState(null);

  useEffect(() => {
    async function getInsta(){
      const api_insta = "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,username,timestamp,thumbnail_url,permalink&access_token=IGQVJXMmR1cHl1U2gwUURqTVZArckdNRG12OGs3VWZA0bXVWNXlURXBjODJGa09RQ2tId1hrSXBLa0VuMDZAaQjVJTmg4NXZAYQ3EzVmJEYlVmb2V3ZA1pEOFdyV0Q3WEdSbzFKaTZAwZA3VKQ2RBNV9OTFp6NwZDZD";
      await fetch(api_insta)
      .then(response => response.json())
      .then((jsonData) => {
          // console.log("Insta")
          // console.log(jsonData.data)
          setDataInsta(jsonData.data)
      })   
    }

    getInsta()
  },[])

    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
      setOpen(true);
    }
    function closeSuscribe(){
      setOpen(false);
    }

  const isClient = useIsClient();
  if(isClient){
    return (
      <>
        <ThemeProvider theme={main}>
          <CssBaseline />
          <HeaderLayout ticker={false} openModal={openSuscribe}/>

          <div className={classes.container}>
            <Grid container spacing={0}>
  
              <Grid item xs={12} md={8}>
  
                {/* Wrapper Header Basics */}
                <div className={classes.HeaderBasics}>
                  <div className={classes.HeaderAutor}>
                    <Avatar className={classes.avatarPic} src={post.authorObject.avatar.gatsbyImageData?.images.fallback.src} alt=""/>
                    <div className={classes.HeaderAutorInfo}>
                      <p>{post.authorObject.nombre}, <span>{post.date}</span></p>
                    </div>
                  </div>
  
                  <div className={classes.HeaderTags}>
                    {
                      post.tags && post.tags.map((node:any) => {
                        return (
                          <Link key={node.slug} to={`/tags/${node.slug}`}>
                            <span>{node.nombre}</span>
                          </Link>
                        );
                      })
                    }
                  </div>
  
                </div>
  
                <div>
                  <h1 style={{marginBottom:".7rem"}}>{post.title}</h1>
                  <h2>{post.subtitle}</h2>
                </div>
  
                <Grid container className={classes.IntroBasics}>
                  <Grid item xs={12} md={12} className="wrap_text">
                    <div>
                      {renderRichText(post.textohead,options)}
                    </div>
                  </Grid>

                  {
                    post.imagehead.gatsbyImageData?.images &&
                    <Grid item xs={12} md={12} className="wrap_img">
                      <div>
                        <picture>
                          <img src={post.imagehead?.gatsbyImageData.images?.fallback == undefined ? post.imagehead?.gatsbyImageData.images.sources[0].srcSet.split(",").slice(-1)[0].split(" ")[0] : post.imagehead?.gatsbyImageData.images?.fallback.src } alt="" />
                        </picture>
                      </div>
                    </Grid>
                  }
  
                </Grid>
  
  
                <div className={classes.BodyBasics}>
                  {post.textogeneral && renderRichText(post.textogeneral,options)}
                </div>
  
              </Grid>
              <Grid item xs={12} md={4} style={{paddingLeft:"1rem"}}>
              
                <div style={{position: "relative", height: "80vh"}}>
                  <Timeline
                    dataSource={{
                      sourceType: 'profile',
                      screenName: 'vectoranalisis'
                    }}
                    options={{
                      height: '600'
                    }}
                  />
                </div>
  
  
              </Grid>
            </Grid>

          </div>
          <div className="container">
            <RecentPosts posts={dataRecent.recentArticles} title="Lo más reciente"/>
          </div>
          {showFooter && <CustomFooter openModal={openSuscribe}/>}
          {
            open && <ModalSuscribe closeModal={closeSuscribe}/>
          }
        </ThemeProvider>
      </>
    );
  }else{
    return(<></>)
  }
}


export const pageQuery = graphql`
  query($slug: String!) {
    contentfulVectorBasics(slug: { eq: $slug }) {
      ...vectorBasics
    }
  }
`;
